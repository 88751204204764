import { useEffect } from "react";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const SignIn = () => {
	const authURL = `${REACT_APP_MAIN_SERVER_URL}/api/oauth/login?redirectTo=${window.location.href}`;
	useEffect(() => {
		window.location.replace(authURL);
	}, [authURL]);

	return null;
};

export default SignIn;