import { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Route, Routes, BrowserRouter as Router, useLocation } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import * as Sentry from "@sentry/browser";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorBoundary } from "react-error-boundary";
import { CssBaseline } from "@mui/material";
import { Auth0Provider } from '@auth0/auth0-react';

import "./index.scss";
import colors from "./_colors.scss";
import "react-table-6/react-table.css";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Protected from "./components/Protected.js";
import GuestOnly from "./components/GuestOnly.js";
import AllAccess from "./components/AllAccess.js";
import ErrorFallback from "./components/ErrorFallback.js";
import Snackbar from "./components/Snackbar.js";
import NotFound from "./screens/NotFound.js";
import SignIn from "./screens/SignIn.js";
import Auth from "./screens/Auth.js";
import Teams from "./screens/Teams.js";
import TeamMembers from "./screens/TeamMembers.js";
import TeamProjects from "./screens/TeamProjects.js";
import TeamEvents from "./screens/TeamEvents.js";
import TeamPublications from "./screens/TeamPublications.js";
import Publications from "./screens/Publications.js";
import Assignments from "./screens/Assignments.js";
import TeamInfo from "./screens/TeamInfo.js";
import PublicTeamInfo from "./screens/PublicTeamInfo.js";
import PublicationInfo from "./screens/PublicationInfo.js";
import PublicTeamMembers from "./screens/PublicTeamMembers.js";
import PublicTeamProjects from "./screens/PublicTeamProjects.js";
import PublicProjectInfo from "./screens/PublicProjectInfo.js";
import Profile from "./screens/Profile.js";
import { adjustColors, jwt, colorSuggestions } from "./utils/index.js";

import "./i18n.js";
import ProjectInfo from "./screens/ProjectInfo.js";

const {
	NODE_ENV,
	REACT_APP_SENTRY_DSN,
	REACT_APP_SENTRY_ENVIRONMENT,
	REACT_APP_AUTH0_DOMAIN,
	REACT_APP_AUTH0_CLIENT_ID,
} = process.env;

Sentry.init({
	dsn: REACT_APP_SENTRY_DSN,
	environment: REACT_APP_SENTRY_ENVIRONMENT,
	ignoreErrors: [
		"ResizeObserver loop limit exceeded",
		"Non-Error promise rejection captured",
	],
	enabled: NODE_ENV === "production",
});

const theme = createTheme({
	palette: {
		primary: {
			main: colors.primary,
			light: colors.primaryLight || adjustColors(colors.primary, 100),
			dark: colors.primaryDark || adjustColors(colors.primary, -80),
		},
		secondary: {
			main: colors.secondary || colorSuggestions.secondary,
			light: colors.secondaryLight || adjustColors(colors.secondary || colorSuggestions.secondary, 100),
			dark: colors.secondaryDark || adjustColors(colors.secondary || colorSuggestions.secondary, -80),
		},
		third: {
			main: colors.third || colorSuggestions.third,
			light: colors.thirdLight || adjustColors(colors.third || colorSuggestions.third, 100),
			dark: colors.thirdDark || adjustColors(colors.third || colorSuggestions.third, -80),
		},

		success: { main: colors.success },
		error: { main: colors.error, dark: colors.errorDark },
		warning: { main: colors.warning },
		info: { main: colors.info },

		dark: { main: colors.dark },
		light: { main: colors.light, dark: colors.lightDark },
		grey: { main: colors.grey, dark: colors.greyDark },
		green: { main: colors.green, dark: colors.greenDark },
		white: { main: "#ffffff", dark: colors.whiteDark },
	},
});

const App = () => {
	const location = useLocation();
	const [authenticated, setAuthenticated] = useState(false);
	const [publicPage, setPublicPage] = useState(false);
	const [properHeight, setProperHeight] = useState("100vh");

	useEffect(() => {
		setAuthenticated(jwt.isAuthenticated());
		setPublicPage(location.pathname.endsWith("/public"));
		if (location.pathname.endsWith("/public")) setProperHeight("calc(100vh)");
		else if (jwt.isAuthenticated()) setProperHeight(`calc(100vh - 100px)`);
		else setProperHeight(`calc(100vh - 70px)`);
	}, [location]);

	return (
		<StyledEngineProvider injectFirst>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						{!publicPage && <Header isAuthenticated={authenticated} />}
						<main style={{ position: "relative", zIndex: 0, height: properHeight, backgroundColor: theme.palette.primary.main }}>
							<Routes>
								<Route index element={<GuestOnly c={<SignIn />} />} />
								<Route path="auth" element={<GuestOnly c={<Auth />} />} />
								<Route path="profile" element={<Protected c={<Profile />} />} />

								{/* Public */}
								<Route path="teams/:teamid/publications/public" element={<AllAccess c={<PublicTeamInfo content="publication" />} />} />
								<Route path="teams/:teamid/membersOnly/public" element={<AllAccess c={<PublicTeamMembers />} />} />
								<Route path="teams/:teamid/members/public" element={<AllAccess c={<PublicTeamMembers allMembers />} />} />
								<Route path="teams/:teamid/theses/public" element={<AllAccess c={<PublicTeamInfo content="master_thesis" />} />} />
								<Route path="teams/:teamid/phds/public" element={<AllAccess c={<PublicTeamInfo content="phd_thesis" />} />} />
								<Route path="teams/:teamid/projects/public" element={<AllAccess c={<PublicTeamProjects />} />} />
								<Route path="teams/:teamid/projects/:projectid/public" element={<AllAccess c={<PublicProjectInfo />} />} />

								{/* Private */}
								<Route path="teams" element={<Protected c={<Teams />} />} />
								<Route path="publications" element={<Protected c={<Publications />} />} />
								<Route path="assignments" element={<Protected c={<Assignments />} />} />

								{/* Team specific */}
								<Route path="teams/:teamid" element={<Protected c={<TeamInfo />} />} />
								<Route path="teams/:teamid/publications" element={<Protected c={<TeamPublications content="publication" />} />} />
								<Route path="teams/:teamid/theses" element={<Protected c={<TeamPublications content="master_thesis" />} />} />
								<Route path="teams/:teamid/phds" element={<Protected c={<TeamPublications content="phd_thesis" />} />} />
								<Route path="teams/:teamid/proposals" element={<Protected c={<TeamPublications content="proposal" />} />} />
								<Route path="teams/:teamid/events" element={<Protected c={<TeamEvents />} />} />
								<Route path="teams/:teamid/members" element={<Protected c={<TeamMembers />} />} />
								<Route path="teams/:teamid/projects" element={<Protected c={<TeamProjects />} />} />
								<Route path="teams/:teamid/projects/:projectid" element={<Protected c={<ProjectInfo />} />} />

								{/* Publication specific */}
								{/* <Route path="teams/:teamid/documents/:publicationid" element={<Protected c={<PublicationInfo />} />} /> */}
								<Route path="teams/:teamid/theses/:publicationid" element={<Protected c={<PublicationInfo />} />} />
								<Route path="teams/:teamid/publications/:publicationid" element={<Protected c={<PublicationInfo />} />} />
								<Route path="teams/:teamid/phds/:publicationid" element={<Protected c={<PublicationInfo />} />} />
								<Route path="teams/:teamid/proposals/:publicationid" element={<Protected c={<PublicationInfo />} />} />

								<Route path="*" element={<NotFound />} />
							</Routes>
							{authenticated && !publicPage && <Footer />}
						</main>
						<Snackbar />
					</LocalizationProvider>
				</ErrorBoundary>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

const root = ReactDOM.createRoot(document.querySelector("#root"));
root.render(
	<Auth0Provider
		domain={REACT_APP_AUTH0_DOMAIN}
		clientId={REACT_APP_AUTH0_CLIENT_ID}
		authorizationParams={{
			redirect_uri: window.location.origin,
		}}
	>
		<Router>
			<App />
		</Router>
	</Auth0Provider>,
);
