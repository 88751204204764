import { useEffect, useState } from "react";
import { Grid, TextField, Button, Box, Select, MenuItem, Divider, Typography, Alert } from "@mui/material";
import { makeStyles } from '@mui/styles';
import Form from "../Form.js";
import Popup from "../Popup.js";
import {
	publicationTypes,
} from "../../utils/publication-constants.js";
import {
	addPublicationToTeam,
	getTeamMembers,
	addTeamAuthor,
	getTeamPublications,
	addNonTeamAuthor,
	addExternalAuthor,
	updatePublicationInfo,
	updatePublication,
	deleteTeamAuthorFromPublication,
	deleteNonTeamAuthorFromPublication,
	deleteExternalAuthorFromPublication,
} from "../../api/index.js";
import { BibtexParser } from "bibtex-js-parser";

const useStyles = makeStyles((theme) => ({
	uploadButton: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
		padding: '1rem',
	},
	input: {
		color: "black",
		width: "100%",
		backgroundColor: "white",
		opacity: 0.7,
		borderRadius: "4px",
		marginBottom: "10px",
		"&:hover": {
			opacity: 0.8,
		},
	},
	select: {
		color: theme.palette.green.main,
		"&:before": {
			borderColor: theme.palette.green.main,
		},
		"&:after": {
			borderColor: theme.palette.green.main,
		},
	},
	fileType: {
		color: "white",
		marginTop: "1rem",
	},
	wideInput: {
		color: "black",
		width: "500px",
		backgroundColor: "white",
		opacity: 0.7,
		borderRadius: "4px",
		marginBottom: "10px",
		"&:hover": {
			opacity: 0.8,
		},
	},
}));

const titleWidth = "20%";
const fieldWidth = "80%";

const addPublicationFormContent = [
	{
		customType: "button",
		id: "submit",
		type: "submit",
		text: "Add publication",
	},
];

const updatePublicationFormContent = [
	{
		customType: "button",
		id: "submit",
		type: "submit",
		text: "Update publication",
	},
];

const bibtexTypeMatchingArray = [
	{ bibtexValue: "article", value: "Article" },
	{ bibtexValue: "book", value: "Book" },
	{ bibtexValue: "booklet", value: "Book" },
	{ bibtexValue: "conference", value: "Conference" },
	{ bibtexValue: "inbook", value: "Book chapter" },
	{ bibtexValue: "incollection", value: "Conference" },
	{ bibtexValue: "inproceedings", value: "Conference" },
	{ bibtexValue: "manual", value: "Misc" },
	{ bibtexValue: "masterthesis", value: "Misc" },
	{ bibtexValue: "misc", value: "Misc" },
	{ bibtexValue: "phdthesis", value: "Misc" },
	{ bibtexValue: "proceedingns", value: "Conference" },
	{ bibtexValue: "techreport", value: "Misc" },
	{ bibtexValue: "unpublished", value: "Misc" },
];

const bibtexFieldsMatchingArray = [
	{ bibtexValue: "keywords", value: "keywords" },
	{ bibtexValue: "abstract", value: "abstract" },
	{ bibtexValue: "url", value: "published_url" },
	{ bibtexValue: "journal", value: "publishedTarget" },
	{ bibtexValue: "booktitle", value: "publishedTarget" },
	{ bibtexValue: "volume", value: "volume" },
	{ bibtexValue: "number", value: "issue" },
	{ bibtexValue: "pages", value: "pages" },
	{ bibtexValue: "raw", value: "citation" },
	{ bibtexValue: "year", value: "publicationDate" },
	{ bibtexValue: "month", value: "publicationDate" },
];

const monthMap = {
	January: 1,
	February: 2,
	March: 3,
	April: 4,
	May: 5,
	June: 6,
	July: 7,
	August: 8,
	September: 9,
	October: 10,
	November: 11,
	December: 12,
};

const latexCommands = [
	'\\textit', // Italic text
	'\\textbf', // Bold text
	'\\underline', // Underlined text
	'\\texttt', // Typewriter (monospace) text
	'\\textsc', // Small caps text
	'\\frac', // Fraction
	'\\sum', // Summation
	'\\int', // Integral
	'\\sqrt', // Square root
	'\\alpha', // Greek letter alpha
	'\\beta', // Greek letter beta
	'\\gamma', // Greek letter gamma
	'\\section', // Section heading
	'\\subsection', // Subsection heading
	'\\paragraph', // Paragraph heading
	'\\item', // List item
	'\\begin', // Begin an environment
	'\\end', // End an environment
	'\\cite', // Citation
	'\\ref', // Reference
	'\\label', // Label for references
	'\\footnote', // Footnote
	'\\textcolor', // Text color
	'\\emph', // Emphasized text
	'\\mathbf', // Bold math symbols
	'\\mathcal', // Calligraphic font
	'\\lim', // Limit
	'\\sin', // Sine function
	'\\cos', // Cosine function
	'\\tan', // Tangent function
	'\\log', // Logarithm function
	'\\pm', // Plus-minus symbol
	'\\cdots', // Centered dots
	'\\ldots', // Ellipsis
	'\\begin{itemize}', // Start a bullet list
	'\\begin{enumerate}', // Start a numbered list
	'\\begin{table}', // Start a table
	'\\begin{figure}', // Start a figure
	'\\caption', // Caption for figures/tables
	'\\', // escape character
];

// const bibtexFields = [
// 	"author",         // Authors of the work
// 	"title",          // Title of the work
// 	"year",           // Year of publication
// 	"journal",        // Journal (for @article)
// 	"booktitle",      // Book title (for @inproceedings, @conference, etc.)
// 	"volume",         // Volume number (for journals, books)
// 	"number",         // Issue number (for journals)
// 	"pages",          // Page numbers
// 	"month",          // Month of publication
// 	"note",           // Miscellaneous notes
// 	"publisher",      // Publisher (for books, conference proceedings)
// 	"address",        // Address of the publisher
// 	"edition",        // Edition of a book
// 	"editor",         // Editor of the work (for collections, books)
// 	"institution",    // Institution (for technical reports)
// 	"school",         // School (for theses)
// 	"series",         // Series (for books)
// 	"chapter",        // Chapter number (for books)
// 	"howpublished",   // How it was published (for informal publications)
// 	"type",           // Type of publication (e.g., technical report)
// 	"doi",            // Digital Object Identifier
// 	"isbn",           // ISBN (for books)
// 	"issn",           // ISSN (for journals)
// 	"url",            // URL to access the document
// 	"eprint",         // Preprint or electronic identifier (e.g., arXiv)
// 	"organization",   // Organization (for conferences, sponsors)
// 	"crossref",       // Cross-reference to another BibTeX entry
// 	"abstract",       // Abstract or summary of the work
// 	"keywords",       // Keywords for indexing
// 	"language",       // Language of the publication
// 	"location",       // Location (for conferences)
// 	"translator",     // Translator of the work
// 	"annote",         // Annotation for personal notes
// 	"bibsource",      // Source from which the BibTeX entry was obtained
// 	"institution",    // Institution publishing a report or paper
// 	"version",        // Version of the publication
// 	"medium",         // Medium of the publication (e.g., print, digital)
// 	"archivePrefix",  // Archive prefix for eprints (e.g., arXiv)
// 	"primaryClass"    // Primary class of the publication (for arXiv)
// ];

const ImportFilePopup = ({
	open,
	setOpen,
	teamId,
	setIsLoading,
	success,
	error,
	fetchData,
	isAddition,
	isUpdate,
	publication,
	isImport,
	setImportFilePopupOpen,
	setPasteTextFilePopupOpen,
	teamPublications,
	setFailedCitations,
}) => {
	const classes = useStyles();
	const [selectedFile, setSelectedFile] = useState(null);
	const [fields, setFields] = useState([]);
	const [newPublicationType, setNewPublicationType] = useState([]);
	const [fileJson, setFileJson] = useState([]);
	const [authors, setAuthors] = useState([]);
	const [authorsTypes, setAuthorsTypes] = useState([]);
	const [errors, setErrors] = useState([null]);
	const [teamMembers, setTeamMembers] = useState([]);
	const [bibtexText, setBibtexText] = useState(null);
	const [submitedBibtexText, setSubmitedBibtexText] = useState(null);
	const [unacceptedFile, setUnacceptedFile] = useState(false);
	const [pubsTitles, setPubsTitles] = useState([]);
	const [pubsToAddTitles, setPubsToAddTitles] = useState([]);

	const fetchMembers = async (teamId) => {
		try {
			const { success: scs, members: mems } = await getTeamMembers(teamId);
			if (scs) {
				setTeamMembers(mems);
			} else {
				error();
			}
		} catch (error_) {
			console.log(error_);
			error();
		}
	};

	const getPubliSyncTypeValue = (bibtexValue) => {
		const type = bibtexTypeMatchingArray.find((item) => item.bibtexValue === bibtexValue);
		return type ? type.value : "";
	};

	useEffect(() => {
		const publicationsTitles = [];
		if (isAddition) {
			for (const pub of teamPublications) {
				publicationsTitles.push(pub.title);
			}
		}

		setPubsTitles(publicationsTitles);
		if (publication) {
			teamId = publication.team;
		}

		if (selectedFile || submitedBibtexText) {
			fetchMembers(teamId);

			const processBibtexContent = (content) => {
				for (const command of latexCommands) {
					if (content.includes(command)) {
						content = content.replaceAll(command, '');
					}
				}

				const updatedContent = content.replace(/{.*,/, '{a,');
				const publicationFile = BibtexParser.parseToJSON(updatedContent);
				setFileJson((prevFileJson) => [...prevFileJson, publicationFile[0]]);

				const keyValueArray = Object.keys(publicationFile[0]).map((key) => {
					let value = publicationFile[0][key];

					if (key === "author") {
						value = value.split(/\s+and\s+/);
					}

					return { key, value };
				});

				setFields((prevFields) => [...prevFields, keyValueArray]);

				if (isAddition) {
					const importedTitle = keyValueArray.find((item) => item.key === "title");
					setPubsToAddTitles((prevTitles) => [...prevTitles, importedTitle.value]);
				}

				const importedAuthors = keyValueArray.find((item) => item.key === "author");
				const importedAuthorsTypes = importedAuthors.value.map(() => "External Author");
				setAuthors((prevAuthors) => [...prevAuthors, importedAuthors.value]);
				setAuthorsTypes((prevAuthorsTypes) => [...prevAuthorsTypes, importedAuthorsTypes]);

				const importedType = keyValueArray.find((item) => item.key === "type");

				const newType = getPubliSyncTypeValue(importedType.value);
				if (newType === "") {
					setNewPublicationType((prevNewPublicationTypes) => [...prevNewPublicationTypes, "Misc"]);
				} else {
					setNewPublicationType((prevNewPublicationTypes) => [
						...prevNewPublicationTypes, getPubliSyncTypeValue(importedType.value)]);
				}
			};

			if (selectedFile) {
				const reader = new FileReader();
				reader.addEventListener('load', (e) => {
					const pubsToUpload = (e.target.result).split('@');
					pubsToUpload.shift();

					if (isUpdate && pubsToUpload.length > 1) {
						setUnacceptedFile(true);
					} else {
						setUnacceptedFile(false);
						for (const count in pubsToUpload) {
							if (Object.hasOwn(pubsToUpload, count)) {
								pubsToUpload[count] = `@${pubsToUpload[count]}`;
								processBibtexContent(pubsToUpload[count]);
							}
						}
					}
				});

				reader.readAsText(selectedFile);
			}

			if (submitedBibtexText) {
				const pubsToUpload = (submitedBibtexText).split('@');
				pubsToUpload.shift();

				if (isUpdate && pubsToUpload.length > 1) {
					setUnacceptedFile(true);
				} else {
					setUnacceptedFile(false);
					for (const count in pubsToUpload) {
						if (Object.hasOwn(pubsToUpload, count)) {
							pubsToUpload[count] = `@${pubsToUpload[count]}`;
							processBibtexContent(pubsToUpload[count]);
						}
					}
				}
			}
		}
	}, [selectedFile, submitedBibtexText]);

	const handleFileChange = (event) => {
		setSelectedFile(null);
		setBibtexText(null);
		setSubmitedBibtexText(null);
		setAuthors([]);
		setAuthorsTypes([]);
		setNewPublicationType([]);
		setFields([]);
		setPubsTitles([]);
		setPubsToAddTitles([]);
		setNewPublicationType([]);
		setFileJson([]);
		setSelectedFile(event.target.files[0]);
	};

	const handleBibtexText = (event) => {
		setSelectedFile(null);
		setBibtexText(null);
		setSubmitedBibtexText(null);
		setAuthors([]);
		setAuthorsTypes([]);
		setNewPublicationType([]);
		setFields([]);
		setPubsTitles([]);
		setPubsToAddTitles([]);
		setNewPublicationType([]);
		setFileJson([]);
		setBibtexText(event.target.value);
	};

	const handleFieldChange = (ind, index, event) => {
		const updatedFields = [...fields];
		updatedFields[ind][index].value = event.target.value;
		if (updatedFields[ind][index].key === "type") {
			const updatedPublicationTypes = [...publicationTypes];
			updatedPublicationTypes[ind] = event.target.value;
			setNewPublicationType(updatedPublicationTypes);
		}

		setFields(updatedFields);
	};

	const handleAuthorChange = (ind, authorIndex, event) => {
		const updatedAuthors = [...authors];
		updatedAuthors[ind][authorIndex] = event.target.value;

		if (authorsTypes[ind][authorIndex] === "Non Team Member") {
			const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			const newErrors = [...errors];
			newErrors[ind][authorIndex] = emailPattern.test(event.target.value) ? null : "Enter email address for non team members";
			setErrors(newErrors);
		}

		setAuthors(updatedAuthors);
	};

	const handleAuthorTypeChange = (ind, authorIndex, event) => {
		const updatedAuthorsTypes = [...authorsTypes];
		updatedAuthorsTypes[ind][authorIndex] = event.target.value;

		const updatedErrors = [...errors];

		updatedErrors[ind] = [];
		if (event.target.value === "Non Team Member") {
			const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			updatedErrors[ind][authorIndex] = emailPattern.test(authors[ind][authorIndex]) ? null : "Enter email address for non team members";
		} else {
			updatedErrors[ind][authorIndex] = null;
		}

		setErrors(updatedErrors);
		setAuthorsTypes(updatedAuthorsTypes);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		let isSuccess = false;
		const failures = [];
		try {
			for (const count in fileJson) {
				// eslint-disable-next-line no-continue
				if (!Object.hasOwn(fileJson, count)) continue;
				if (isAddition) {
					const { success: successCode } = await addPublicationToTeam(
						teamId,
						fileJson[count].title,
						fileJson[count].doi,
						newPublicationType[count],
						"Published",
					);
					isSuccess = successCode;
					if (!isSuccess && !failures.includes(count)) {
						failures.push(count);
					}
				}

				if (isUpdate) {
					const newTitle = fields[count].find((field) => (field.key === 'title' ? field.value : null));

					const newDoi = fields[count].find((field) => (field.key === 'doi' ? field.value : null));

					const newTitleValue = newTitle ? newTitle.value : publication.title;
					const newDoiValue = newDoi ? newDoi.value : publication.doi;

					const { success: successCode } = await updatePublication(publication._id, newTitleValue, newDoiValue);
					isSuccess = successCode;
					if (!isSuccess && !failures.includes(count)) {
						failures.push(count);
					}
				}

				if (isSuccess) {
					// Get the id of the publication that was created
					let publicationForAuthors;
					if (isAddition) {
						const { success: scs, publications: pubs } = await getTeamPublications(teamId, "publication");
						publicationForAuthors = pubs.at(-1);
						isSuccess = scs;
						if (!isSuccess && !failures.includes(count)) {
							failures.push(count);
						}
					}

					if (isUpdate) {
						publicationForAuthors = publication;
						for (const teamAuthor of publication.team_authors) {
							await deleteTeamAuthorFromPublication(publicationForAuthors._id, teamAuthor);
						}

						for (const nonTeamAuthor of publication.non_team_authors) {
							await deleteNonTeamAuthorFromPublication(publicationForAuthors._id, nonTeamAuthor);
						}

						for (const externalAuthor of publication.external_authors) {
							await deleteExternalAuthorFromPublication(publicationForAuthors._id, externalAuthor);
						}
					}

					if (isSuccess) {
						// Authors addition based on their Author type
						for (const authCount in authors[count]) {
							// eslint-disable-next-line no-continue
							if (!Object.hasOwn(authors[count], authCount)) continue;
							if (authorsTypes[count][authCount] === "Team Member") {
								const member = teamMembers.find((mem) => mem.fullname === authors[count][authCount]);
								if (member) {
									const { success: scs } = await addTeamAuthor(publicationForAuthors._id, member.userId);
									isSuccess = scs;
									if (!isSuccess && !failures.includes(count)) {
										failures.push(count);
									}
								}
							}

							if (authorsTypes[count][authCount] === "Non Team Member") {
								const { success: scs } = await addNonTeamAuthor(publicationForAuthors._id, authors[count][authCount]);
								isSuccess = scs;
								if (!isSuccess && !failures.includes(count)) {
									failures.push(count);
								}
							}

							if (authorsTypes[count][authCount] === "External Author") {
								const { success: scs } = await addExternalAuthor(publicationForAuthors._id, authors[count][authCount]);
								isSuccess = scs;
								if (!isSuccess && !failures.includes(count)) {
									failures.push(count);
								}
							}
						}

						const publicationInfo = {};
						publicationInfo.keywords = [];
						let pubMonth = '1';
						let hasYear = false;
						let pubYear;
						for (const fieldCount in fields[count]) {
							// eslint-disable-next-line no-continue
							if (!Object.hasOwn(fields[count], fieldCount)) continue;
							const validField = bibtexFieldsMatchingArray.find((field) => field.bibtexValue === fields[count][fieldCount].key);
							if (validField) {
								if (validField.value === "keywords") {
									publicationInfo[validField.value] = fields[count][fieldCount].value.split(/[,;]/);
								} else if (validField.bibtexValue === 'year') {
									hasYear = true;
									pubYear = fields[count][fieldCount].value;
								} else if (validField.bibtexValue === 'month') {
									const formattedMonthString = fields[count][fieldCount].value.charAt(0).toUpperCase()
										+ fields[count][fieldCount].value.slice(1).toLowerCase();

									pubMonth = monthMap[formattedMonthString] || '1';
								} else {
									publicationInfo[validField.value] = fields[count][fieldCount].value;
								}
							}
						}

						if (hasYear) {
							publicationInfo.publicationDate = `1/${pubMonth}/${pubYear}`;
						}

						await updatePublicationInfo(publicationForAuthors._id, publicationInfo);
					}

					success(isAddition ? "Publication created successfully" : "Publication updated successfully");
				}
			}

			setFailedCitations(failures.length > 0 && isAddition ? failures.length : null);
		} catch (error_) {
			console.error(error_);
		}

		await fetchData();
		setIsLoading(false);
		setSelectedFile(null);
		setBibtexText(null);
		setSubmitedBibtexText(null);
		setFields([]);
		setErrors([]);
		setNewPublicationType([]);
		setFileJson([]);
		setAuthors([]);
		setAuthorsTypes([]);
		setNewPublicationType([]);
		setUnacceptedFile(false);
		setImportFilePopupOpen(false);
		setPasteTextFilePopupOpen(false);
		setOpen(false);
	};

	const handleClose = () => {
		setSelectedFile(null);
		setBibtexText(null);
		setSubmitedBibtexText(null);
		setFields([]);
		setErrors([]);
		setNewPublicationType([]);
		setFileJson([]);
		setAuthors([]);
		setAuthorsTypes([]);
		setNewPublicationType([]);
		setUnacceptedFile(false);
		setImportFilePopupOpen(false);
		setPasteTextFilePopupOpen(false);
		setOpen(false);
	};

	return (
		<Popup
			width="800px"
			open={open}
			title={isImport ? `Import BibTeX file` : 'Paste BibTeX text'}
			onClose={handleClose}
		>
			<Grid
				container
				flexDirection="column"
				alignItems="center"
				spacing={2}
				sx={{ width: "100%", padding: 3 }}
			>
				{isImport ? (
					<Button
						variant="contained"
						component="label"
						className={classes.uploadButton}
					>
						{selectedFile ? "Change File" : "Choose File"}
						<input
							hidden
							type="file"
							onChange={handleFileChange}
						/>
					</Button>
				) : (
					<Box
						sx={{ mt: 2 }}
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<Box
							color="third.main"
							sx={{ mb: 1, textAlign: 'center', fontSize: '1.2rem', fontWeight: 'bold' }}
						>
							{"BibTeX text"}
						</Box>
						<Box
							width="500px"
						>
							<TextField
								multiline
								id="bibtex"
								name="bibtex"
								type="text"
								variant="filled"
								color="primary"
								className={classes.wideInput}
								rows={8}
								value={bibtexText}
								onChange={(event) => handleBibtexText(event)}
							/>
						</Box>
						<Box sx={{ mt: 2 }}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									setSubmitedBibtexText(bibtexText);
								}}
							>
								{submitedBibtexText ? 'Change text' : 'Submit'}
							</Button>
						</Box>
					</Box>

				)}
				{unacceptedFile ? (
					<Typography color="error" variant="body1">
						{"Only one publication is accepted."}
					</Typography>
				) : (
					<>
						{
							fields.length > 0 && (
								<>
									{fields.map((publ, ind) => (
										<Box key={ind} mt={2} width="100%" sx={{ marginBottom: "1rem" }}>
											<Divider sx={{ width: "100%", marginBottom: "1rem" }} />
											{isAddition && pubsTitles.includes(pubsToAddTitles[ind]) ? (
												<Alert severity="warning">
													{"A publication with the same title already exists. If you add it, a new publication"}
													{"with the same title will be created."}
												</Alert>
											) : (
												<></>
											)}
											{publ.map((field, index) => (
												<Box
													key={index}
													sx={{ mt: 2 }}
													display="flex"
													flexDirection="row"
													alignItems="center"
												>
													{field.key !== "raw" && field.key !== "id" && (
														<>
															<Box width={titleWidth} color="third.main">
																{`${field.key}:`}
															</Box>
															<Box width={fieldWidth}>
																{field.key === "type" ? (
																	<Select
																		fullWidth
																		id="type"
																		name="type"
																		color="primary"
																		value={newPublicationType[ind]}
																		className={classes.input}
																		onChange={(event) => handleFieldChange(ind, index, event)}
																	>
																		{publicationTypes.slice(1).map((e, pubInd) => (
																			<MenuItem key={pubInd} value={e.value}>{e.text}</MenuItem>
																		))}
																	</Select>
																) : field.key === "author" ? (
																	<>
																		{field.value.map((_, authorIndex) => (
																			<Grid
																				key={authorIndex}
																				container
																				flexDirection="row"
																				sx={{ marginBottom: "1rem" }}
																			>
																				<Grid item sx={{ marginRight: "1rem" }}>
																					{authorsTypes[ind][authorIndex] === "Team Member" ? (
																						<Select
																							id={`${authorIndex}`}
																							value={authors[ind][authorIndex]}
																							className={classes.input}
																							onChange={(event) => handleAuthorChange(ind, authorIndex, event)}
																						>
																							{teamMembers.map((member) => (
																								<MenuItem key={member.userId} value={member.fullname}>
																									{member.fullname}
																								</MenuItem>
																							))}
																						</Select>
																					) : (
																						<TextField
																							id={`author-${authorIndex}`}
																							name="author"
																							type="text"
																							variant="filled"
																							color="primary"
																							value={authors[ind][authorIndex]}
																							className={classes.input}
																							error={Boolean(errors[ind] ? errors[ind][authorIndex] : false)}
																							helperText={errors[ind] ? errors[ind][authorIndex] : null}
																							onChange={(event) => handleAuthorChange(ind, authorIndex, event)}
																						/>
																					)}
																				</Grid>
																				<Grid item>
																					<Select
																						id={`${authorIndex}`}
																						name="authorType"
																						color="primary"
																						value={authorsTypes[ind][authorIndex]}
																						className={classes.input}
																						onChange={(event) => handleAuthorTypeChange(ind, authorIndex, event)}
																					>
																						<MenuItem value="Team Member">{"Team Member"}</MenuItem>
																						<MenuItem value="Non Team Member">{"Non Team Member"}</MenuItem>
																						<MenuItem value="External Author">{"External Author"}</MenuItem>
																					</Select>
																				</Grid>
																			</Grid>
																		))}
																	</>
																) : (
																	<TextField
																		fullWidth
																		type="text"
																		variant="filled"
																		color="primary"
																		value={field.value}
																		className={classes.input}
																		onChange={(event) => handleFieldChange(ind, index, event)}
																	/>
																)}
															</Box>
														</>
													)}
												</Box>
											))}
										</Box>
									))}
								</>
							)
						}
						{(selectedFile || submitedBibtexText) && (
							<Form content={isAddition ? addPublicationFormContent : updatePublicationFormContent} onSubmit={handleSubmit} />
						)}
					</>
				)}
			</Grid>
		</Popup>
	);
};

export default ImportFilePopup;
