import { Grid, IconButton, Typography } from "@mui/material";
 import { memo, useEffect, useState } from "react";
 import { Image } from "mui-image";
 import { Close, EditNote } from "@mui/icons-material";
 import { makeStyles } from "@mui/styles";
 
 import Card from "../components/Card.js";
 import Spinner from "../components/Spinner.js";
 import Form from "../components/Form.js";
 import Tooltip from "../components/Tooltip.js";
 import { useSnackbar } from "../utils/index.js";
 import { getProfile, updateProfile } from "../api/index.js";

const useStyles = makeStyles((theme) => ({
    editIcon: {
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.primary.dark,
        },
    },
}));
 
 const Profile = () => {
 	const { error } = useSnackbar();
    const classes = useStyles();
    const [profile, setProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
 
    const fetchProfile = async () => {
        setIsLoading(true);
        const { success, profile } = await getProfile();
        if (success) {
            setProfile(profile);
        } else {
            error();
        }
        setIsLoading(false);
    };
 
    useEffect(() => {
        fetchProfile();
    }, []);

    const onSubmit = async (data) => {
        setIsLoading(true);
        const { success, message } = await updateProfile(data);
        if (success) {
            setIsEditable(false);
            fetchProfile();
        } else {
            error(message);
        }
        setIsLoading(false);
    };

    const formContent = [
        {
            customType: "input",
            id: "fullname",
            type: "text",
            label: "Full Name:",
            placeholder: "Full Name",
            value: profile?.fullname,
        },
        {
            customType: "input",
            id: "username",
            type: "text",
            label: "Username:",
            placeholder: "Username",
            value: profile?.username,
        },
        {
            customType: "button",
            id: "submit",
            type: "submit",
            text: "Submit",
        },
    ];
     
    return (
        <>
            <Spinner open={isLoading} />
            <Grid container display="flex" direction="column" justifyContent="center" alignItems="center">
                <Grid width={500}>
                    <Card
                    title={(
                        <Grid display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", position: "relative" }}>
                            <Typography variant="body" component="h2" fontSize={18}>Profile</Typography>
                            <Grid sx={{ position: "absolute", right: "0px" }}>
                                <Tooltip title={isEditable ? "Close" : "Edit"}>
                                    <IconButton
                                        className={classes.editIcon}
                                        onClick={() => {
                                            setIsEditable((prev) => !prev);
                                        }}
                                    >
                                        {isEditable ? <Close /> : <EditNote />}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )}
                >
                        <Grid width="100%" display="flex" justifyContent="center">
                            <Grid display="flex" justifyContent="center" sx={{ width: "100px" }}>
                                <Image src={profile?.avatar} alt="Avatar" referrerpolicy="no-referrer"  style={{ width: "100px", height: "100px", borderRadius: "100%", objectFit: "contain" }} />
                            </Grid>
                            <Grid display="flex" justifyContent="center" flexDirection="column" sx={{ width: "350px", marginLeft: "10px" }}>
                                <Form disabled={!isEditable} content={formContent} validationSchema="profile" onSubmit={onSubmit} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
 };
 
 export default memo(Profile);
