import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	Chip,
	Box,
	Tab,
	Tabs,
	Menu,
	MenuItem,
	Badge,
} from "@mui/material";
import { makeStyles } from '@mui/styles';

import CustomTabPanel from "../Common/TabPanel.js";
import AssetsPreview from "./AssetsPreview.js";
import AddAssetPopup from "../Popups/AddAssetPopup.js";
import Popup from "../Popup.js";
import Form from "../Form.js";

import {
	getPublicationAssets,
	editPublicationAsset,
	deletePublicationAsset,
} from "../../api/index.js";

const useStyles = makeStyles(() => ({
	tabs: {
		color: "white !important",
	},
	tabsIndicator: {
		backgroundColor: "white",
		height: "4px",
	},
}));

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const Assets = ({
	publication,
	classes,
	setIsLoading,
	success,
	error,
	fetchData,
}) => {
	const [tabValue, setTabValue] = useState(0); // current tab value
	const [addAssetPopupOpen, setAddAssetPopupOpen] = useState(false);
	const [addAssetType, setAddAssetType] = useState(null);
	const [anchorElAddAsset, setAnchorElAddAsset] = useState(null);
	const openAddAsset = Boolean(anchorElAddAsset);
	const [assets, setAssets] = useState([]);
	const [changeTitleAssetPopupOpen, setChangeTitleAssetPopupOpen] = useState(false);
	const [assetToEdit, setAssetToEdit] = useState({});

	const [filesLength, setFilesLength] = useState(0);
	const [imagesLength, setImagesLength] = useState(0);
	const [linksLength, setLinksLength] = useState(0);
	const newClasses = useStyles();

	const changeTitleAssetFormContent = [
		{
			customType: "input",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Title",
			value: assetToEdit.title,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save title",
		},
	];

	const changeAssetFormContentLink = [
		{
			customType: "input",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Title",
			value: assetToEdit.title,
		},
		{
			customType: "input",
			id: "link",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "Link",
			value: assetToEdit.link,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save link",
		},
	];

	const getAssets = async () => {
		// console.log("getting assets");
		try {
			const result = await getPublicationAssets(publication._id);
			if (result.success) {
				// eslint-disable-next-line no-confusing-arrow
				setAssets(result.message.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1));
				// console.log(result.message);
			} else {
				// error(result.message);
			}
		} catch (error_) {
			console.log(error_);
			// error(error_);
		}
	};

	const changeAssetSubmitHandler = async ({ title, link }) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await editPublicationAsset(assetToEdit._id, title, link);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await getAssets();
		setAssetToEdit({});
		setChangeTitleAssetPopupOpen(false);
		setIsLoading(false);
	};

	const handleClickAddAsset = (event) => {
		setAnchorElAddAsset(event.currentTarget);
	};

	const handleCloseAddAsset = () => {
		setAnchorElAddAsset(null);
	};

	const handleChangeTab = (_, newValue) => {
		setTabValue(newValue);
	};

	const editItemClicked = (item) => {
		console.log(item);
		setAssetToEdit(item);
		setChangeTitleAssetPopupOpen(true);
	};

	const deleteItemClicked = async (item) => {
		setIsLoading(true);
		try {
			const res = await deletePublicationAsset(item._id);
			if (res.success) {
				success(res.message);
			} else {
				error(res.message);
			}
		} catch { /* empty */ }

		await getAssets();
		await fetchData();
		setIsLoading(false);
	};

	useEffect(() => {
		// console.log("Assets mounted!");
		getAssets();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setFilesLength(assets.filter((item) => item.type === "file").length);
		setImagesLength(assets.filter((item) => item.type === "image").length);
		setLinksLength(assets.filter((item) => item.type === "link").length);
	}, [assets]);

	return (
		<Grid
			container
			item
			width="100%"
			display="flex"
			flexDirection="column"
			backgroundColor="secondary.main"
			sx={{
				borderRadius: "20px",
				minHeight: "450px",
			}}
			className={classes.publicationInfoCard}
		>
			<Menu
				id="add-user-menu"
				anchorEl={anchorElAddAsset}
				open={openAddAsset}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				onClose={handleCloseAddAsset}
			>
				<MenuItem
					onClick={() => {
						setAnchorElAddAsset(null);
						setAddAssetType("file");
						setAddAssetPopupOpen(true);
					}}
				>
					{"File"}
				</MenuItem>
				<MenuItem
					onClick={() => {
						setAnchorElAddAsset(null);
						setAddAssetType("image");
						setAddAssetPopupOpen(true);
					}}
				>
					{"Image"}
				</MenuItem>
				<MenuItem
					onClick={() => {
						setAnchorElAddAsset(null);
						setAddAssetType("link");
						setAddAssetPopupOpen(true);
					}}
				>
					{"Link"}
				</MenuItem>
			</Menu>
			<Popup
				width="800px"
				open={changeTitleAssetPopupOpen}
				title={assetToEdit.type === 'link' ? "Link edit" : "Title edit"}
				onClose={() => setChangeTitleAssetPopupOpen(false)}
			>
				<Form
					content={assetToEdit.type === 'link' ? changeAssetFormContentLink : changeTitleAssetFormContent}
					onSubmit={changeAssetSubmitHandler}
				/>
			</Popup>
			<AddAssetPopup
				publicationId={publication._id}
				addAssetType={addAssetType}
				open={addAssetPopupOpen}
				setOpen={setAddAssetPopupOpen}
				setIsLoading={setIsLoading}
				folder={publication._id}
				success={success}
				error={error}
				classes={classes}
				getAssets={getAssets}
				setTabValue={setTabValue}
				fetchData={fetchData}
			/>

			<Grid
				container
				item
				width="100%"
				p={1}
				pl={2}
				mb={1}
				display="flex"
				minHeight="50px"
				maxHeight="50px"
				alignItems="center"
				backgroundColor="third.main"
				sx={{
					borderTopLeftRadius: "20px",
					borderTopRightRadius: "20px",
				}}
			>
				<Grid
					item
					xs={6}
				>
					<Typography variant="h6" color="secondary.main">
						{"Assets"}
					</Typography>
				</Grid>
				<Grid
					item
					xs={6}
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="flex-end"
				>
					<Chip
						key={-1}
						label="Add asset"
						className={classes.actionChip}
						sx={{
							mr: 0.5,
						}}
						aria-controls={open ? "basic-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
						onClick={handleClickAddAsset}
					/>
				</Grid>
			</Grid>
			<Grid sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						centered
						value={tabValue}
						aria-label="assets-tabs"
						classes={{ indicator: newClasses.tabsIndicator }}
						onChange={handleChangeTab}
					>
						<Tab
							title="Files"
							label={(
								<Badge badgeContent={filesLength} color="green">
									<Typography sx={{ paddingRight: "8px" }}>{"Files"}</Typography>
								</Badge>
							)}
							className={newClasses.tabs}
							{...a11yProps(0)}

						/>
						<Tab
							title="Images"
							label={(
								<Badge badgeContent={imagesLength} color="green">
									<Typography sx={{ paddingRight: "8px" }}>{"Images"}</Typography>
								</Badge>
							)}
							className={newClasses.tabs}
							{...a11yProps(1)}
						/>
						<Tab
							title="Links"
							label={(
								<Badge badgeContent={linksLength} color="green">
									<Typography sx={{ paddingRight: "8px" }}>{"Links"}</Typography>
								</Badge>
							)}
							className={newClasses.tabs}
							{...a11yProps(2)}
						/>
					</Tabs>
				</Box>
				<CustomTabPanel value={tabValue} index={0}>
					<AssetsPreview
						type="file"
						items={assets.filter((item) => item.type === "file")}
						editItemClicked={editItemClicked}
						deleteItemClicked={deleteItemClicked}
						success={success}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={tabValue} index={1}>
					<AssetsPreview
						type="image"
						items={assets.filter((item) => item.type === "image")}
						editItemClicked={editItemClicked}
						deleteItemClicked={deleteItemClicked}
						success={success}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={tabValue} index={2}>
					<AssetsPreview
						type="link"
						items={assets.filter((item) => item.type === "link")}
						editItemClicked={editItemClicked}
						deleteItemClicked={deleteItemClicked}
						success={success}
					/>
				</CustomTabPanel>
			</Grid>
		</Grid>
	);
};

export default Assets;
