import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { memo, useCallback, useEffect, useState } from "react";
import Spinner from "../components/Spinner.js";
import { useSnackbar, jwt } from "../utils/index.js";
import GenericMdEditor from "../components/MdEditor.js";
import ProjectInformationElement from "../components/ProjectInformationElement.js";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {
	getProject,
	saveProjectInformation,
	getTeam,
} from "../api/index.js";

const useStyles = makeStyles((theme) => ({
	editIcon: {
		color: theme.palette.third.main,
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
}));
import EditInfoPopup from "../components/Popups/EditInfoPopups.js";

const ProjectInfo = () => {
	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [project, setProject] = useState([]);
	const [tagsSelectPopupOpen, setTagsSelectPopupOpen] = useState(false);
	const [textualPopupOpen, setTextualPopupOpen] = useState(false);
	const [checkboxPopupOpen, setCheckboxPopupOpen] = useState(false);
	const [checkboxesDropdownPopupOpen, setCheckboxesDropdownPopupOpen] = useState(false);
	const [numericalPopupOpen, setNumericalPopupOpen] = useState(false);
	const [datepickerPopupOpen, setDatepickerPopupOpen] = useState(false);
	const [textualMultilinePopupOpen, setTextualMultilinePopupOpen] = useState(false);
	const [currentKey, setCurrentKey] = useState("");
	const [currentTitle, setCurrentTitle] = useState("");
	const [teamTags, setTeamTags] = useState([]);
	const classes = useStyles();

	const { projectid } = useParams();
	const { id: myid } = jwt.decode();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, project: prj, message } = await getProject(projectid);
				if (scs) {
					console.log(prj);
					setProject(prj);
					const { success: scs2, team: tm } = await getTeam(prj.team);
					if (scs2) {
						tm.tags.sort((a, b) => {
							if (a.toLowerCase() < b.toLowerCase()) {
								return -1;
							}

							if (a.toLowerCase() > b.toLowerCase()) {
								return 1;
							}

							return 0;
						});
						setTeamTags(tm.tags);
					} else {
						console.log(message);
						error(message ?? '');
					}
				} else {
					console.log(message);
					error(message ?? '');
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const saveInformation = async (values) => {
		const key = Object.keys(values)[0];
		const val = values[key];
		setIsLoading(true);
		try {
			const { success: scs, message } = await saveProjectInformation(projectid, key, val);
			if (scs) {
				success(message);
				await fetchData();
			} else {
				error(message);
			}
		} catch (error_) {
			console.log(error_);
			error();
		}

		setTextualPopupOpen(false);
		setTextualMultilinePopupOpen(false);
		setNumericalPopupOpen(false);
		setCheckboxPopupOpen(false);
		setCheckboxesDropdownPopupOpen(false);
		setDatepickerPopupOpen(false);
		setTagsSelectPopupOpen(false);
		setIsLoading(false);
	};

	return (
		<>
			<Spinner open={isLoading} />
			<EditInfoPopup
				open={textualPopupOpen}
				onClose={() => setTextualPopupOpen(false)}
				title={`Change value for ${currentTitle}`}
				currentKey={currentKey}
				currentTitle={currentTitle}
				project={project}
				saveInformation={saveInformation}
				type="text"
			/>

			<EditInfoPopup
				open={numericalPopupOpen}
				onClose={() => setNumericalPopupOpen(false)}
				title={`Change value for ${currentTitle}`}
				currentKey={currentKey}
				currentTitle={currentTitle}
				project={project}
				saveInformation={saveInformation}
				type="number"
			/>

			<EditInfoPopup
				open={checkboxPopupOpen}
				onClose={() => setCheckboxPopupOpen(false)}
				title={`Change value for ${currentTitle}`}
				currentKey={currentKey}
				currentTitle={currentTitle}
				project={project}
				saveInformation={saveInformation}
				type="checkbox"
			/>
			<EditInfoPopup
				open={textualMultilinePopupOpen}
				onClose={() => setTextualMultilinePopupOpen(false)}
				title={`Change value for ${currentTitle}`}
				currentKey={currentKey}
				currentTitle={currentTitle}
				project={project}
				saveInformation={saveInformation}
				type="multiline-text"
			/>
			<EditInfoPopup
				open={checkboxesDropdownPopupOpen}
				onClose={() => setCheckboxesDropdownPopupOpen(false)}
				title={`Change value for ${currentTitle}`}
				currentKey={currentKey}
				currentTitle={currentTitle}
				project={project}
				saveInformation={saveInformation}
				type="checkboxes-dropdown"
				variants={[
					"Software Engineering",
					"Infrastructure",
					"Data Mining/Machine Learning",
					"Cyber Physical Systems",
				]}
			/>
			<EditInfoPopup
				open={tagsSelectPopupOpen}
				onClose={() => setTagsSelectPopupOpen(false)}
				title={`Change value for ${currentTitle}`}
				currentKey={currentKey}
				currentTitle={currentTitle}
				project={project}
				saveInformation={saveInformation}
				type="checkboxes-dropdown"
				variants={teamTags}
			/>
			<EditInfoPopup
				open={datepickerPopupOpen}
				onClose={() => setDatepickerPopupOpen(false)}
				title={`Change value for ${currentTitle}`}
				currentKey={currentKey}
				currentTitle={currentTitle}
				project={project}
				saveInformation={saveInformation}
				type="date-picker"
			/>
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					width="100%"
					xs={12}
					display="flex"
					flexDirection="column"
					sx={{
						borderRadius: "20px",
					}}
				>
					<Grid
						item
						container
						xs={12}
						flexDirection="row"
						justifyContent="space-between"
					>
						<Grid
							item
							xs={3.9}
							overflow='auto'
							mt={2}
						>
							<Grid
								item
								container
								width="100%"
								flexDirection="row"
							>
								<Grid
									mr={2}
								>
									<DriveFileRenameOutlineIcon
										className={classes.editIcon}
										fontSize="small"
										onClick={() => {
											setCurrentKey("logoUrl");
											setCurrentTitle("Logo URL");
											setTextualPopupOpen(true);
										}}
									/>
								</Grid>
								{project.logoUrl && (
									<img
										src={project.logoUrl}
										alt=""
										style={{
											maxHeight: "100px",
											backgroundColor: "#ffffff",
											borderRadius: "10px",
											align: "center",
											padding: "10px",
										}}
									/>
								)}
								{!project.logoUrl && <ImageNotSupportedIcon color="third.main" fontSize="large" />}
							</Grid>

							<ProjectInformationElement
								setPopupOpen={setTextualPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Title"
								infoKey="title"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualMultilinePopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Moto"
								infoKey="oneLiner"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualMultilinePopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Description"
								infoKey="description"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Funding source"
								infoKey="fundingSource"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Operational body"
								infoKey="operationalBody"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Funding code"
								infoKey="fundingCode"
							/>
							<ProjectInformationElement
								setPopupOpen={setDatepickerPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Start date"
								infoKey="startDate"
								infoType="date"
							/>
							<ProjectInformationElement
								setPopupOpen={setDatepickerPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="End date"
								infoKey="endDate"
								infoType="date"
							/>
							<ProjectInformationElement
								setPopupOpen={setNumericalPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Total budget"
								infoKey="totalBudget"
								postfix="€"
							/>
							<ProjectInformationElement
								setPopupOpen={setNumericalPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Team budget"
								infoKey="teamBudget"
								postfix="€"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Team role"
								infoKey="teamRole"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="External page"
								infoKey="externalPage"
							/>
							<ProjectInformationElement
								setPopupOpen={setCheckboxPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Public visilibity"
								infoKey="public"
								infoType="boolean"
							/>
							<ProjectInformationElement
								setPopupOpen={setCheckboxPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Is platform?"
								infoKey="isPlatform"
								infoType="boolean"
							/>
							<ProjectInformationElement
								setPopupOpen={setCheckboxesDropdownPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Research strand"
								infoKey="researchStrand"
								infoType="checkboxes-dropdown"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Slug"
								infoKey="slug"
							/>
							<ProjectInformationElement
								setPopupOpen={setTagsSelectPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Tags"
								infoKey="tags"
								infoType="checkboxes-dropdown"
							/>
							<Grid
								item
								container
								width="100%"
								flexDirection="row"
							>
								<Grid
									mr={2}
								>
									<DriveFileRenameOutlineIcon
										className={classes.editIcon}
										fontSize="small"
										onClick={() => {
											setCurrentKey("snapshotUrl");
											setCurrentTitle("Snapshot URL");
											setTextualPopupOpen(true);
										}}
									/>
								</Grid>
								{project.snapshotUrl && (
									<img
										src={project.snapshotUrl}
										alt=""
										style={{
											maxHeight: "100px",
											backgroundColor: "#ffffff",
											borderRadius: "10px",
											align: "center",
											padding: "10px",
										}}
									/>
								)}
								{!project.snapshotUrl && (
									<Typography sx={{ color: "gray", fontSize: "0.9rem" }}>
										{"Snapshot URL not declared"}
									</Typography>
								)}
							</Grid>
							<ProjectInformationElement project={project} title="Created at" infoKey="createdAt" infoType="date" />
						</Grid>
						<Grid
							item
							xs={8}
							sx={{
								width: "100%",
							}}
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
						>
							{project && (
								<GenericMdEditor
									screen="ProjectInfo"
									element={project}
									myid={myid}
									setIsLoading={setIsLoading}
									success={success}
									error={error}
									mdHeader="Project full description"
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(ProjectInfo);
