import { memo } from "react";
import Popup from "../Popup";
import Form from "../Form";

const EditInfoPopup = ({
    open,
    onClose,
    title,
    currentKey,
    currentTitle,
    project,
    saveInformation,
    type,
    variants = [],
}) => {
    const getFormContent = (type) => {
        switch (type) {
            case 'text':
                return [
                    {
                        customType: 'wideInput',
                        id: currentKey,
                        type: 'text',
                        value: project[currentKey],
                        width: 800,
                        placeholder: '',
                    },
                    {
                        customType: 'button',
                        id: 'submit',
                        type: 'submit',
                        text: `Change ${currentTitle}`,
                    },
                ];

            case 'number':
                return [
                    {
                        customType: 'wideInput',
                        id: currentKey,
                        type: 'number',
                        value: project[currentKey],
                        width: 800,
                        placeholder: '',
                    },
                    {
                        customType: 'button',
                        id: 'submit',
                        type: 'submit',
                        text: `Change ${currentTitle}`,
                    },
                ];

            case 'checkbox':
                return [
                    {
                        customType: 'checkbox',
                        id: currentKey,
                        label: currentTitle,
                        defaultValue: project[currentKey],
                        color: 'third',
                        disabled: false,
                    },
                    {
                        customType: 'button',
                        id: 'submit',
                        type: 'submit',
                        text: `Change ${currentTitle}`,
                    },
                ];

            case 'checkboxes-dropdown':
                return [
                    {
                        customType: 'checkboxes-dropdown',
                        id: currentKey,
                        label: currentTitle,
                        value: project[currentKey],
                        color: 'third',
                        disabled: false,
                        variants: variants || [],
                    },
                    {
                        customType: 'button',
                        id: 'submit',
                        type: 'submit',
                        text: `Change ${currentTitle}`,
                    },
                ];

            case 'date-picker':
                return [
                    {
                        customType: 'date-picker',
                        id: currentKey,
                        type: 'desktop',
                        color: 'third',
                        background: 'primary',
                        label: currentTitle,
                        value: project[currentKey],
                    },
                    {
                        customType: 'button',
                        id: 'submit',
                        type: 'submit',
                        text: `Change ${currentTitle}`,
                    },
                ];

            case 'multiline-text':
                return [
                    {
                        customType: 'wideInput',
                        id: currentKey,
                        type: 'text',
                        value: project[currentKey],
                        width: 800,
                        multiline: true,
                        minRows: 8,
                        placeholder: '',
                    },
                    {
                        customType: 'button',
                        id: 'submit',
                        type: 'submit',
                        text: `Change ${currentTitle}`,
                    },
                ];

            default:
                return [];
        }
    };

    return (
        <Popup width="800px" open={open} title={title} onClose={onClose}>
            {console.log("variants", variants)}  {/* Debugging variants */}
            <Form
                content={getFormContent(type)}
                onSubmit={saveInformation}
            />
        </Popup>
    );
};

export default memo(EditInfoPopup);
